:root {
    --mobile: 43rem;
    scroll-behavior: auto !important;
  }
  body {
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
  }
  


@import 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

@import "./setting/_variable.scss";
//Importing component style
@import "./components/Component.scss";
@import "./components/Global.scss";
