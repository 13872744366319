.pb-30{
    padding-bottom: 30px;
}
.background-primary {
    background-color: $ternay_color;
}
.primary-text{
    color: $secondary_color;
}
.btn-primar{
    background-color: $primary_color;
}
a{
    text-decoration: none;
}
.error{
    color : red;
}
h1{
    font-size: 54px;
    font-weight: 600;
    line-height: 60px;
    margin: 0;
}
h2{
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
    margin: 0px 0px 30px 0px;
}
h5{
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}
h6{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 0 25px 0;
    color: rgb(0, 0, 0, .6);
}
p{
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
    margin: 0px;
}
.img-icon{
    background-color: #7CBB00;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.common-btn{
    background-color: $primary_color;
    color: #fff;
    border: 1px solid $primary_color;
    border-radius: 10px;
    font-family:  "Poppins", serif;
    font-size: 16px;
    font-weight: 400;
    &:hover{
        background-color: #fff;
        border: 1px solid $primary_color;
        color: $primary_color;
    }
}
.odd-btn{
    background-color: #fff;
    border: 1px solid $primary_color;
    border-radius: 10px;
    font-family:  "Poppins", serif;
    font-size: 16px;
    font-weight: 400;
    color: $primary_color;
    &:hover{
        background-color: $primary_color;
        border: 1px solid $primary_color;
        color: #fff;
    }
}
ul{
    list-style: none;
}




@media only screen and (max-width: 991px) {
    h1{
        font-size: 30px;
        font-weight: 600;
        line-height: 34px;
    }
    h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
    }
    h5{
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    h6{
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
    }
    .common-btn,.odd-btn{
        font-size: 14px;
        font-weight: 500;
        line-height: 9.5px;
        border-radius: 5px;
        padding: 15px 50px !important;
    }
}