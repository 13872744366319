//header section

.navbar {
  padding: 5px 15px;
  .navbar-toggler {
    border: none;
    &:focus {
      &:not(:focus-visible) {
        outline: none !important;
        border: none;
        box-shadow: none;
      }
    }
  }

  .navbar-brand {
    img {
      height: 60px;
    }
  }
  .navbar-collapse {
    ul {
      display: flex;
      align-items: center;
      li {
        margin-right: 70px;
        cursor: pointer;
      }
    }
    .btn-section {
      button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    .common-btn,
    .odd-btn {
      width: 120px; // Set a fixed width for buttons
      text-align: center;
      padding: 7px 0;
    }
    
    .common-btn {
    }
    .odd-btn {
      border: 1px solid $primary_color;
    }
  }
}

//Banner section
.banner-section {
  padding: 5% 0% 10% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  h5 {
    font-weight: 600;
  }
  h1 {
    span {
      color: $secondary_color;
    }
  }
  .button-div {
    .common-btn,
    .odd-btn {
      padding: 12px 20px;
      width: 46%;
    }
    .common-btn {
      margin-right: 20px;
    }
  }
  .ellipse {
    height: 110%;
  }
  .banner-img {
    top: 13%;
    height: 80%;
  }
}
//Offer Section
.offer-section {
  padding: 4% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: unset;
    margin-top: 10px;
    .feature-item {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 15px 0px;
      border-radius: 8px;
      // .img-icon {

      //   img {
      //     height: 21px;
      //   }
      // }
    }
  }
  .line-img {
    right: 20%;
    top: 0;
  }
  .circle-img {
    right: 0;
    bottom: 0;
  }
  .upper-img {
    top: 0;
    left: 0;
    transform: scaleY(-1);
  }
  .bigline-img {
    left: 19%;
    top: 0;
  }
}

//Why section
.why-section {
  padding: 4% 0%;
  background: linear-gradient(180deg, #ffffff 0%, #f7fcf4 60%);

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: unset;
    margin-top: 9px;
    .feature-item {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 15px 0px;
      border-radius: 8px;
      line-height: 30px;
      // }
    }
  }
  button {
    padding: 10px 50px;
    margin-top: 20px;
  }
}

//Module section
.module-section {
  padding: 4% 0%;
  .accordion {
    .accordion-item {
      border: none !important; /* Removes the border */
      box-shadow: none !important; /* Removes shadow effects */
      background-color: transparent;
    }
    // .accordion-button {
    //   padding: 15px 12px;
    //   border-radius: unset !important;
    //   box-shadow: none !important;
    //   background-color: transparent;
    //   &.collapsed {
    //     border-bottom: 1px solid #d9d9d9;
    //   }
    //   &:not(.collapsed) {
    //     background-color: $ternay_color;
    //   }
    //   .icon {
    //     padding-right: 10px;
    //   }
    //   &::after {
    //     content: none !important;
    //   }
    //   .accordion-header-content {
    //     display: flex;
    //     flex-direction: column;
    //   }
    //   .accordion-icon {
    //     content: "";
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     width: 20px;
    //     height: 20px;
    //     border-radius: 50%;
    //     font-size: 15px;
    //     font-weight: bold;
    //     color: #bebebe;
    //     transform: rotate(0deg);
    //     background: none;
    //     border: 1px solid #bebebe;
    //     box-shadow: none;
    //   }

    //   &.collapsed .accordion-icon::after {
    //     content: "+";
    //   }
    //   &:not(.collapsed) .accordion-icon::after {
    //     content: "-";
    //     color: $secondary_color;
    //   }

    //   p {
    //     font-size: 16px;
    //     font-weight: 300;
    //     line-height: 26px;
    //   }
    // }
    .accordion-button {
      padding: 15px 12px;
      border-radius: unset !important;
      box-shadow: none !important;
      background-color: transparent;
      // Ensure no border initially
      // border-bottom: 1px solid #d9d9d9 !important; // Add border-bottom when collapsed
      &.collapsed {
        border-bottom: 1px solid $secondary_color !important; // Add border-bottom when collapsed
        .accordion-icon::after {
          content: "+";
          color: $secondary_color;
        }
      }

      &:not(.collapsed) {
        // border-bottom: none !important; // Remove border-bottom when expanded
        background-color: $ternay_color;
        .accordion-icon::after {
          content: "-";
          color: $secondary_color;
        }
        h5 {
          color: #000;
        }
      }

      .icon {
        padding-right: 15px;
      }

      &::after {
        content: none !important;
      }

      .accordion-header-content {
        display: flex;
        flex-direction: column;
      }

      .accordion-icon {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 5px;
        border-radius: 15%;
        font-size: 15px;
        font-weight: bold;
        color: #bebebe;
        transform: rotate(0deg);
        background: none;
        border: 1px solid $secondary_color;
        box-shadow: none;
      }
    }

    .accordion-body {
      background-color: $ternay_color;
      font-size: 15px;
      font-weight: 300;
      line-height: 20px;
      padding-top: 0;
      ul {
        padding-left: 4rem;
      }
    }
  }
  .common-btn {
    padding: 10px 50px;
  }
}
//Client
.client-section {
  padding: 4% 0%;

  .client-banner {
    height: 70%;
    bottom: 15%;
    right: 12px;
  }
  .common-btn {
    padding: 10px 50px;
  }
  .client-logos {
    background-color: #fff;
    padding: 15px;
    border-radius: 9px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.0784313725);
    h5 {
      margin-bottom: 26px;
    }
  }
}
//Pricing{
.pricing-section {
  padding: 4% 0%;
  min-height: 500px;
  .module-list {
    box-shadow: 0px 2px 8px 0px #00000014;
    padding: 11px 15px;
    border-radius: 10px;
    background-color: #fff;

    cursor: pointer;
    &.selected {
      background-color: $secondary_color;
      color: #fff;
      svg {
        fill: #fff; // White icon for minus
      }
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    .calculation-div {
      padding: 6px 15px;
      .price-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;
      }
      h4 {
        font-size: 36px;
        font-weight: 600;
        line-height: 60px;
      }

      .discount-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 60px;
      }
     
    }
  }
  .total-saving {
    border: 1px dashed $secondary_color;
    border-radius: 10px;
    padding: 6px 6px;
  }
  .monthly-btn,
  .yearly-btn {
    font-size: 15px;
    font-weight: 500;
    line-height: 45px;
    padding: 0px 35px;
    border-radius: 50px;
    background-color: #fff;
    color: $secondary_color;
    border: 1px solid $secondary_color;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active {
      background-color: $secondary_color;
      color: white;
      border-color: $secondary_color;
    }
  }
  .monthly-btn {
    margin-right: 20px;
  }
}
//Faqs
.faqs-section {
  padding: 4% 0%;

  .accordion {
    .accordion-item {
      margin: 10px;
      border: none !important; /* Removes the border */
      box-shadow: none !important; /* Removes shadow effects */
      .accordion-header {
        line-height: 20px;
        .accordion-button {
          &:not(.collapsed) {
            box-shadow: none;
            background: #fff;
            color: #000;
          }
          &.collapsed {
            box-shadow: none;
          }
        }
        &:focus {
          box-shadow: unset;
        }
      }
      .accordion-body {
        text-align: start;
        padding-top: 0px;
        font-size: 13px;
      }
    }
  }
}
//Ready Section
.ready-section {
  padding: 4% 0%;
  background: linear-gradient(180deg, #ffffff 0%, #f7fcf4 60%);
  h6 {
    span {
      font-weight: 600;
    }
  }
  button {
    padding: 10px 50px;
    margin-top: 4%;
  }
  img {
    top: 0;
    right: 0;
    transform: scaleY(-1);
  }
}

//Contact
.contact-form-modal {
  .modal-dialog{
    width: 60%;
  }
  .close-button {
    cursor: pointer;
  }
  .heading-contact {
    display: flex;
    justify-content: start;
    padding-bottom: 10px;
  }
  .line-side {
    background: $secondary_color;
    height: 2px;
    margin-top: 10px !important;
    width: 82px;
  }
  .line-content {
    font-size: 14px;
    padding-left: 10px;
    color: $secondary_color;
  }
  .hl-text {
    color: $secondary_color;
  }
  p {
    padding: 0px 0 25px 0;
  }

  .btn-close {
    font-size: 16px;
    margin-right: 15px;
    &:focus {
      outline: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  .modal-body {
    padding: 20px 30px;

    input {
      padding: 4% 7%;
      &::-webkit-input-placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
      }
      &:focus {
        outline: none !important;
        border: 1px solid #d9d9d9;
        box-shadow: none;
      }
    }
    button {
      font-size: 18px;
      font-weight: 400;
      line-height: 21.78px;
      padding: 11px 50px;
    }
  }
}
.static-page{
  padding: 30px 0px;
  p{
    line-height: 20px !important;
  }
}

//Footer
.footer {
  img {
    height: 60px;
    margin-bottom: 20px;
  }
  .footer-row {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;

  }
  .footer-logo-column {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    .contact-info {
      p {
        a {
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
  .footer-second-column {
    // ul {
    //   margin: 0;
    //   display: flex;
    //   padding-left: 10px;
    //   li {
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 30px;
    //     cursor: pointer;
    //     &:not(:last-child) {
    //       margin-right: 50px;
    //     }
    //   }
    // }
  }
  .ourshop_com {
    color: rgba(0, 0, 0, 0.45);
   padding: 5px !important;
  }
  .copyright {
    
    
    .middle_paragraph {
      font-size: 14px;
      display: flex;
      justify-content: center;
      line-height: 19px;
      gap: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    
  }
  .footer-color-content {
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px) {
  //Header

  .navbar {
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 10;
    .navbar-brand {
      img {
        height: 50px;
      }
    }

    .navbar-collapse {
      ul {
        align-items: flex-start;
        li {
          padding: 10px 0px;
          font-size: 14px;
          a {
            font-size: 14px;
          }
        }
      }
      .common-btn,
      .odd-btn {
        width: 120px; 
        padding: 10px 5px !important;
      }
    }
  }

  //Banner section
  .banner-section {
    padding: 30% 7% 10% 7%;
    .button-div {
      .common-btn,
      .odd-btn {
        width: 85%;
        padding: 13px 20px;
      }
      .common-btn {
        margin: 0px 0px 20px 0px;
      }
    }
  }
  .banner-img-mob {
    left: 0;
    width: 100%;
    bottom: 10px;
  }
  //Offer
  .offer-section {
    padding: 10% 10px;
    ul {
      margin: 20px 0px;
      .feature-item {
        padding: 0;
        .icon-container {
          border-radius: 5px;
        }
      }
    }
  }
  //Why section
  .why-section {
    padding: 10% 10px;
    ul {
      margin: 20px 0px;
      .feature-item {
        padding: 0;
        .icon-container {
          border-radius: 5px;
          img {
            height: 21px;
          }
        }
      }
    }
    button {
      border-radius: 5px;
    }
  }
  //Module section
  .module-section {
    padding: 10% 10px;
    .accordion {
      .accordion-body {
        font-size: 14px;
        font-weight: 300;
        line-height: 15px;
        ul {
          padding-left: 3rem;
        }
      }
    }
  }
  //Client
  .client-section {
    .client-text {
      padding: 10% 10px;
    }
  }
  //Pricing{
  .pricing-section {
    padding: 10% 10px;
    .monthly-btn,
    .yearly-btn {
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
  }
  //Faq
  .faqs-section {
    .accordion-button {
      font-size: 16px;
    }
    .accordion-body {
      font-size: 14px !important;
    }
  }
  //Ready
  .ready-section {
    padding: 10% 10px;
    h2 {
      padding: 0px 35px;
    }
    h6 {
      padding: 0px 36px;
    }
    button {
      border-radius: 5px;
      padding: 13px 50px;
      margin-top: 8%;
    }
  }
  .contact-form-modal {
    .modal-dialog{
      width: 100%;
    }
  }

  //Footer
  .footer {
    img {
      height: 50px !important;
      margin-bottom: 30px;
    }
    // .footer-row {
    //   padding: 7% 10px;
    // }
    .pb-30 {
      padding-bottom: 5px;
    }
    .quick-section{
      padding: 10px 0px;
      .quick-links {
        ul {
          li {
            font-size: 14px;
            font-weight: 400;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.45);
            &:not(:last-child) {
              margin-right: 30px !important;
            }
          }
        }
      }
    }
    
    .footer-second-column{

    }
    .ourshop_com{
      font-size: 12px;
      padding: 15px !important;
    }
    .copyright {
      padding: 8px 0px;
      span {
        font-size: 10px;
        font-weight: 300;
        line-height: 22.5px;
      }
    }
    
    .footer-color-content {
      color: rgba(0, 0, 0, 0.45) !important;
      font-weight: 500 !important;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      font-size: 14px;
      text-decoration: none;
    }

  }
}
.footer-header{
  line-height: 21px;
}
.conditionslinks{
  @media only screen and (max-width: 991px){
    margin-top: 60px !important;
  }
}
.discount-price {
  padding-left: 5px;
  margin: 0;
  font-weight: 700;
  font-size:20px;

}
.discount-text{
  margin-top: 2px;
}
.pricingbtn{
  padding: 10px 50px;
}